<template>
  <b-form-group>
    <b-form-input
      v-model="name"
      class="w-auto d-inline-block mr-1"
      @change="saveItem"
    />
    <feather-icon v-if="loading" icon="LoaderIcon" class="mr-50" />
    <feather-icon
      icon="ImageIcon"
      class="mr-50 cursor-pointer"
      @click="openModal"
    />
    <feather-icon
      icon="TrashIcon"
      class="mr-50 cursor-pointer"
      @click="$emit('deleteOption', index)"
    />

    <b-modal
      v-model="isImageModalOpen"
      ok-only
      ok-title="Закрыть"
      modal-class="modal-primary"
      centered
      title="Primary Modal"
    >
      <ImagesToOptionValue :image="optionImage" @imageUpdated="updateImage" />
    </b-modal>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput, BModal } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crud_module from '@/libs/crud_module'
import ImagesToOptionValue from '@/views/shop/product-options/ImagesToOptionValue.vue'

const product_option_value_module = crud_module({
  entity_name: 'product-option/value',
})

export default {
  name: 'OptionValue',
  components: {
    ImagesToOptionValue,
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      name: this.value,
      isImageModalOpen: false,
      optionImage: this.image,
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.name = newVal
      }
    },
  },
  methods: {
    async saveItem() {
      if (this.id) {
        this.loading = true
        await product_option_value_module
          .patchItem(this.id, {
            name: this.name,
            imageId: this.image?.id || null,
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
            this.loading = false
          })
        this.loading = false
      }
      this.$emit('optionUpdated', this.index, {
        imageId: this.optionImage?.id,
        name: this.name,
      })
    },
    openModal() {
      this.isImageModalOpen = !this.isImageModalOpen
    },
    async updateImage(image) {
      this.optionImage = image

      if (this.id) {
        await this.saveItem()
      }

      this.$emit('optionUpdated', this.index, {
        imageId: this.optionImage?.id,
        name: this.name,
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
