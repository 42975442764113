<template>
  <div>

    <div
      v-if="optionImage"
      class="mb-2"
    >
      <h3>Фото</h3>
      <div class="image-wrapper">
        <div class="image-container">
          <feather-icon
            icon="TrashIcon"
            size="25"
            class="delete-icon cursor-pointer"
            @click="deleteImage()"
          />
          <img
            class="image"
            :src="optionImage.fullPath"
            :alt="optionImage.name"
          >
        </div>
      </div>
    </div>

    <b-row>
      <b-col md="12">
        <div class="upload-wrap">
          <file-uploader
            title="Фото опции"
            @updatePhotos="addImage"
          />
        </div>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import FileUploader from '@/global-components/FileUploader.vue'

export default {
  name: 'ImagesToOptionValue',
  components: {
    BCol,
    BRow,
    FileUploader,
  },
  props: {
    image: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },
  data() {
    return {
      optionImage: this.image,
    }
  },
  methods: {
    addImage(image) {
      this.optionImage = image
      this.$emit('imageUpdated', this.optionImage)
    },
    deleteImage() {
      this.optionImage = null
      this.$emit('imageUpdated', null)
    },
  },
}
</script>

<style scoped lang="scss">
.image-wrapper {

  .image-container {
    position: relative;

    .delete-icon {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .image {
      width: 100%;
      height: 100%;
      border-radius: 7px;
      border: 1px solid #a4a4a4;
    }
  }
}
</style>
