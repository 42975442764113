<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Название опции">
                <b-form-input v-model="option.name" placeholder="Название" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <b-form-checkbox
          v-model="option.in_filter"
          class="mr-1 mt-50"
          name="in_filter"
          switch
          inline
        />
        <label for="in_filter">В фильтр</label>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Добавить новое значение опции">
                <b-form-input v-model="newOptionValue" placeholder="Значение" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <div class="mr-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="addOptionValue"
          >
            Добавить
          </b-button>
        </div>

        <div class="ml-1 mt-2">
          <option-value
            v-for="(value, index) in optionValues"
            :id="value.uuid"
            :key="index"
            :value="value.name"
            :index="index"
            :image="value.image"
            @deleteOption="deleteOptionValue(index)"
            @optionUpdated="onOptionUpdated"
          />
        </div>
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner v-if="isloading" small />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crud_module from '../../../libs/crud_module'
import FileUploader from '@/global-components/FileUploader'
import OptionValue from '@/views/shop/product-options/OptionValueComponent.vue'

const product_option_module = crud_module({
  entity_name: 'product-option',
})

const product_option_value_module = crud_module({
  entity_name: 'product-option/value',
})

export default {
  name: 'ProductOption',
  components: {
    OptionValue,
    quillEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    FileUploader,
    BFormCheckbox,
    OptionValue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      option: {},
      newOptionValue: '',
      optionValues: [],
    }
  },
  async created() {
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await product_option_module
        .fetchItem(this.$route.params.id, {
          query: {
            admin: '1',
          },
        })
        .then(result => {
          this.option = result.data
          this.optionValues = result.data.values
          this.isInFilter = result.data.in_filter
        })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    saveItem() {
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    async update() {
      try {
        this.option.values = this.optionValues
        await product_option_module
          .patchItem(this.option.uuid, this.option)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Опция сохранена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'product-options' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async create() {
      try {
        this.option.values = this.optionValues
        await product_option_module
          .createItem(this.option)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Опция добавлена',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'product-options' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async createOptionValue(value) {
      const payload = {
        name: value,
        product_option_uuid: this.$route.params.id,
      }
      try {
        await product_option_value_module
          .createItem(payload)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Значение опции добавлено',
                variant: 'success',
              },
            })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    addOptionValue() {
      if (this.$route.params.id === 'new') {
        this.optionValues.push({
          name: this.newOptionValue,
          imageId: null,
        })
      } else {
        this.optionValues.push({
          product_option_uuid: this.$route.params.id,
          name: this.newOptionValue,
          imageId: null,
        })
      }

      this.newOptionValue = ''
    },
    deleteOptionValue(index) {
      this.optionValues = [
        ...this.optionValues.slice(0, index),
        ...this.optionValues.slice(index + 1),
      ]
    },
    onOptionUpdated(index, newOptionValues) {
      this.optionValues[index] = newOptionValues
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.value-item {
  margin-top: 10px;
  font-weight: 600;

  svg {
    cursor: pointer;
  }
}
</style>
